import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import AdminLayout from './layouts/admin';
import VNCViewer from './views/VNCViewer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClusters } from './reducers/clusterSlice';
import { fetchClusterState } from './reducers/clusterStateSlice';
import { setConfig } from './reducers/configSlice';
import { checkUserStatus } from './api';
import { useTracking } from './hooks/usePageTracking';
import Error from './views/error';
import { Affix, Alert, Spin } from 'antd';

const WrappedSwitch = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useTracking();

  const { token, accountId, workspaceId, userMetadata } = useSelector((state) => state.config);

  const params = new URLSearchParams(history.location.search);
  const accessToken = params.get('accessToken') || token;
  const _accountId = params.get('accountId') || accountId;
  const _workspaceId = params.get('workspaceId') || workspaceId;
  const _userMetadata = params.get('userMetadata') || userMetadata;
  const _wsUrl = params.get('wsUrl')

  useEffect(() => {
    // one time running to check if access token exists and extracts it
    if (accessToken) {
      dispatch(setConfig({
        token: accessToken,
        accountId: _accountId,
        workspaceId: _workspaceId,
        userMetadata: _userMetadata,
      }));
      if (history.location.pathname.includes('vnc-view') && _wsUrl) {
        history.replace(`${history.location.pathname}${_wsUrl}`);
      } else {
        history.replace(history.location.pathname);
      }
    } else {
      history.replace('/onboarding', ['Token not provided']);
    }
  }, [history, dispatch, accessToken, _accountId, _workspaceId, _userMetadata, _wsUrl]);

  useEffect(() => {
    accessToken && checkUserStatus().then((res) => {
      const { data = {} } = res;
      const {
        status,
        hasAuthHeader,
        hasFrameHeader,
        tokenIsValid,
        netappMetaValid,
        profileExists,
        isOnboarded,
      } = data;
      if (status !== 'INVALID') {
        window?.parent?.postMessage?.({ type: 'SERVICE:READY' }, '*');
        const redirectingLocationPathname = history.location.pathname === '/' ? '/overview' : history.location.pathname;
        history.replace({
          ...history.location,
          pathname: redirectingLocationPathname
        });
      } else if (!hasAuthHeader || !hasFrameHeader || !tokenIsValid || !netappMetaValid) {
        history.replace('/error', ['Missing query parameters. Please refresh to try again.']);
      } else if (!isOnboarded && !profileExists) {
        history.replace('/onboarding', [{ infoOnly: true }]);
      } else if (!isOnboarded && profileExists) {
        history.replace('/onboarding', [{ infoOnly: true, registered: profileExists }]);
      } else if (!profileExists) {
        history.replace('/onboarding', [{ infoOnly: false }]);
      }
    }).catch(() => {
      history.replace('/error', ['Server not responding!']);
    });

    const messageListener = (event) => {
      const message = event.data;
      if (message.type === 'SERVICE:WORKSPACE-CHANGE') {
        dispatch(setConfig({
          workspaceId: message.payload.workspaceId
        }));
        dispatch(fetchClusters);
        history.push({
          ...history.location,
          search: `?accessToken=${accessToken}&workspaceId=${message.payload.workspaceId}&userMetadata=${encodeURIComponent(_userMetadata)}&accountId=${_accountId}`,
        });
        history.go(0);
      }
      if (message.type === 'SERVICE:TOKEN-UPDATE') {
        dispatch(setConfig({
          token: message.payload.accessToken,
          userMetadata: message.payload.userMetadata
        }));
      }
    }
    window?.addEventListener?.('message', messageListener, false);
    return () => {
      window?.removeEventListener?.('message', messageListener);
    }

  }, [dispatch, history, accessToken, _accountId, _userMetadata, _workspaceId]);

  return <div>
    <Switch {...props}>
      <Route exact path='/error'><Error /></Route>
      {children}
    </Switch>
    {window.location.hostname === 'portal-test.fluid.aseit.com.au' &&
      <div style={{position: "absolute", top: "10em"}}>
        <button onClick={() => window.open(`http://localhost:3000/?token=${accessToken}&frameMeta=${JSON.stringify({
            accountId: _accountId,
            workspaceId: _workspaceId,
            userMetadata: _userMetadata
          })}`, '_blank')}>
          Localhost
        </button>
        <button onClick={() => window.open(`https://portal-v2-test.fluidhq.io/?token=${accessToken}&frameMeta=${JSON.stringify({
            accountId: _accountId,
            workspaceId: _workspaceId,
            userMetadata: _userMetadata
          })}`, '_blank')}>
          V2
        </button>
      </div>
    }
  </div>;
};

const App = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.clusterState);
  const { target } = useSelector((state) => state.clusters);
  const { token } = useSelector((state) => state.config);

  useEffect(() => {
    const getClusterState = () => {
      if (token) { // only fetch and update for banner if we have a token i.e. the user has onboarded
        dispatch(fetchClusters);
        dispatch(fetchClusterState(target));
      }
    }
    getClusterState()
    const interval = setInterval(() => getClusterState(), 10 * 1000);
    return () => clearInterval(interval);
  }, [dispatch, target, token]);

  return (
    <BrowserRouter>
      {
        data?.showBanner 
        ?
          <Affix style={{ position: 'relative', zIndex: 105 }}>
            <Alert 
              message={data?.percentage + '% complete. ' + data?.message} 
              type='info' 
              banner
              icon={<Spin style={{paddingRight: '10px'}}/>}
            />
          </Affix> 
        : 
          <></>
      }

      <WrappedSwitch>
        <Route
          path='/vnc-view/:wsUrl'
          render={(props) => <VNCViewer {...props} />}
          key='VNC Viewer'
          exact
        />
        <Route path='/*' render={(props) => <AdminLayout {...props} />} />
      </WrappedSwitch>
    </BrowserRouter>
  );
};

export default App;
