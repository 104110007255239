import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button'; 
import Select from 'antd/lib/select';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from 'antd/lib/typography';
import useForm from 'antd/lib/form/hooks/useForm';
import { CopyOutlined } from '@ant-design/icons';
import { createMetalBootKey } from '../../api';
import { fetchMetalBootKeys } from '../../reducers/metalBootKeysSlice';
import notification from '../../utils/notification';
import './modal.scss';

const { Paragraph } = Typography;
const { Option } = Select;

const AddMetalClusterBootKeyModal = ({ visible, loading, onCancel, metalClusterInfo }) => {
  const [form] = useForm();
  const [selectedSite, setSelectedSite] = useState('default');
  const [clusterBootKey, setClusterBootKey] = useState('');
  const [isGeneratingBootKey, toggleIsGeneratingBootKey] = useState(false);
  const [nameValue, setNameValue] = useState(null);
  const dispatch = useDispatch();

  const generateClusterBootKey = async () => {
    const values = form.getFieldsValue();
    toggleIsGeneratingBootKey(true);
    try {
      const resp = await createMetalBootKey(metalClusterInfo.uuid, values);
      dispatch(fetchMetalBootKeys(metalClusterInfo.uuid));
      setClusterBootKey(resp?.data?.uuid);
      notification.success({
        message: 'New PXE boot key added',
        placement: 'bottomRight'
      });
    } catch (e) {
      notification.error({
        message: 'Something went wrong, please try again later',
        placement: 'bottomRight'
      });
    }
    toggleIsGeneratingBootKey(false);
  }

  function copyUrl() {
    return `http://boot.fluidhq.io/pxe/${clusterBootKey}/${selectedSite}/boot.ipxe`;
  }

  const selectSite = (
    <Select defaultValue={'default'} onChange={(e) => setSelectedSite(e)}>
      <Option value={'default'}>Default</Option>
      <Option value={'equinix'}>Equinix</Option>
    </Select>
  );

  const copyBootKey = (
    <Tooltip title='Copy boot key'>
      <Button 
        className='clipboard-btn'
        icon={<CopyOutlined/>}
        onClick={() => navigator.clipboard.writeText(copyUrl())}
      />
    </Tooltip>
  )

  const generateBootKeyBtn = (
    <Button type='primary' onClick={generateClusterBootKey} loading={isGeneratingBootKey} disabled={!nameValue}>Generate Boot Key</Button>
  )

  return (
    <Modal
      visible={visible}
      title='Add PXE Boot Key'
      onCancel={onCancel}
      width={640}
      footer={[
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={onCancel}
        >
          OK
        </Button>
      ]}
    >
      <Form form={form} layout='vertical' >
        <Paragraph>Generate PXE Boot Keys to create and add nodes to the cluster.</Paragraph>
        <Col span={24} className={'modal-col'}>
          <p>
            <b className='boot-key-label'>Cluster Name:</b> {metalClusterInfo.name}
          </p>
        </Col>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <Form.Item
              name='name'
              className='modal-item-label'
              label='Name'
              validateTrigger='onBlur'
              hasFeedback
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input className='modal-item-controller' placeholder='Name' onChange={(e) => setNameValue(e.target.value)}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <b className='boot-key-label'>Boot Key</b>
            <Input className='generate-group'
              addonAfter={generateBootKeyBtn}
              value={clusterBootKey}
              contentEditable={false}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className={'modal-col'}>
            <b className='boot-key-label'>PXE URL</b>
            <Input className='copy-group'
              addonBefore={selectSite}
              addonAfter={copyBootKey}
              value={copyUrl()} 
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddMetalClusterBootKeyModal;
