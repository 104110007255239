import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import ClusterOutlined from '@ant-design/icons/ClusterOutlined';
import NodeIndexOutlined from '@ant-design/icons/NodeIndexOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import ApiOutlined from '@ant-design/icons/ApiOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { useHistory, useParams } from 'react-router';

import Header from '../../components/header';
import { fetchClusters } from '../../reducers/clusterSlice';
import { fetchCloudInitConfig } from '../../reducers/cloudInitConfigSlice';
import { fetchNodes } from '../../reducers/nodesSlice';
import { fetchCapacity } from '../../reducers/capacitySlice';
import { updateBreadcrumbs } from '../../reducers/breadcrumbSlice';
import MetalNodes from './metalNodes';
import MetalBootKeys from './metalBootKeys'
import SshKeys from './sshKeys';
import ClusterActions from './actions';

import './index.scss';
import ShowStatus from '../../components/showstatus';
import { Button } from 'antd';

const ClusterDetails = () => {
  const { data } = useSelector((state) => state.clusters);
  const { data: cloudInitConfig} = useSelector((state) => state.cloudInitConfig);
  const params = useParams();
  const history = useHistory();
  const { clusterUuid } = params;
  const [clusterDetails, setClusterDetails] = useState({
    clusterUuid
  });

  const dispatch = useDispatch();

  useEffect(() => {

    function getInformation() {
      dispatch(fetchClusters);
      params?.clusterUuid && dispatch(fetchNodes(params?.clusterUuid)) 
                          && dispatch(fetchCapacity(params?.clusterUuid))
                          && dispatch(fetchCloudInitConfig(params?.clusterUuid));
    }
    
    getInformation();
    const interval = setInterval(() => getInformation(), 10 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cluster = JSON.parse(JSON.stringify((data || []).find(cluster => cluster?.clusterUuid === clusterUuid)) || '{}');
    const startScript = cloudInitConfig?.find(cloudInitConfig => cloudInitConfig.clusterUuid === clusterUuid)?.startScript;
    if (cluster) {
      cluster.startScript = startScript;
      setClusterDetails(cluster);
    }
  }, [data, cloudInitConfig, clusterUuid])

  useEffect(() => {
    dispatch(updateBreadcrumbs([
      {
        title: 'Environments',
        link: '/clusters'
      }, {
        title: 'Environment Details'
      }
    ]))
    return () => {
      dispatch(updateBreadcrumbs([]));
    }
  }, [dispatch]);

  const goViewClusters = () => {
    history.push('/clusters');
  };

  return (
    <div className="cluster-container">
      <Header
        items={undefined}
        title={undefined}
        description=' '
      />
      {clusterDetails ? <>
          <Row justify='space-between' className='cluster-detail-info'>
            <Row justify='space-between'>
              <Col span={5} className='cluster-detail-info-block'>
                <div className='icon-block'>
                  <ClusterOutlined />
                </div>
                <div className='cluster-detail-info-description'>
                  <div>{clusterDetails?.clusterName || 'Unavailable'}</div>
                  <div className='cluster-detail-sub-title'>Name</div>
                </div>
              </Col>
              <Col span={5} className='cluster-detail-info-block'>
                <div className='icon-block'>
                  <NodeIndexOutlined />
                </div>
                <div className='cluster-detail-info-description'>
                  <div>{clusterDetails?.nodes?.length || 'Unavailable'}</div>
                  <div className='cluster-detail-sub-title'>Nodes</div>
                </div>
              </Col>
              <Col span={5} className='cluster-detail-info-block'>
                <div className='icon-block'>
                  <GlobalOutlined />
                </div>
                <div className='cluster-detail-info-description'>
                  <div>{clusterDetails?.location || 'Unavailable'}</div>
                  <div className='cluster-detail-sub-title'>Location</div>
                </div>
              </Col>

              <Col span={5} className='cluster-detail-info-block'>
                <div className='icon-block'>
                  <ApiOutlined />
                </div>
                <div className='cluster-detail-info-description'>
                  <ShowStatus status={clusterDetails?.clusterState || undefined} />
                  <div className='cluster-detail-sub-title'>Cluster State</div>
                </div>
              </Col>
            </Row>
            <ClusterActions record={clusterDetails} />
          </Row>
          <Row justify='space-between' gutter={40}>

            {/*<Col span={8}>*/}
            {/*  <Deployments record={clusterDetails} />*/}
            {/*</Col>*/}

            {
              <Col span={24} className='cluster-detail-hidden-col'>
                <MetalNodes clusterDetails={clusterDetails}/>
                
              </Col>
            }
            {
              <Col span={24} className='cluster-detail-hidden-col'>
                <MetalBootKeys clusterDetails={clusterDetails}/>
              </Col>
            }
            {
              <Col span={24} className='cluster-detail-hidden-col'>
                <SshKeys clusterDetails={clusterDetails}/>
              </Col>
            }
            {/* <Services record={clusterDetails} /> */}
            
          </Row>
        </> :
        <div className='cluster-not-available'>
          <div className='message-container'>
            <ExclamationCircleOutlined />
            The cluster you are trying to access is not available. Please go back and try again.
          </div>
          <Button className='view-clusters-btn' type='primary' onClick={goViewClusters}>View Clusters</Button>
        </div>
      }
    </div>
  );
};

export default ClusterDetails;
